import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Api } from '../../api/Api';
import { Filter } from '../filter/Filter';
import { Row } from '../row/Row';
import { withRouter } from 'react-router-dom';

class Home extends Component {

    state = {
        randomWord: undefined,
        slides: undefined,
        number: undefined,
        wideTallOnly: undefined
    };

    params = new URLSearchParams(window.location.search);

    async componentDidMount() {
        const {api} = this.props;

        const randomWordParam = this.getSearchParam('randomWord');
        const number = this.getSearchParamNumber('number');
        const wideTallOnly = this.getSearchParamBoolean('wideTallOnly');

        const {randomWord, slides} = await api.getJSON({randomWord: randomWordParam, number, wideTallOnly});
        this.addRandomWordToSearch(randomWord, wideTallOnly);
        this.setState({
            randomWord,
            slides,
            number,
            wideTallOnly
        });
    }

    /**
     * @param {String} propertyName
     * @return {String | undefined}
     * @private
     */
    getSearchParam(propertyName) {
        return this.params.get(propertyName) || undefined;
    }

    /**
     * @param {String} propertyName
     * @return {Number | undefined}
     * @private
     */
    getSearchParamNumber(propertyName) {
        return +this.getSearchParam(propertyName) || undefined;
    }

    /**
     * @param {String} propertyName
     * @return {Boolean}
     * @private
     */
    getSearchParamBoolean(propertyName) {
        return this.getSearchParam(propertyName) === 'true';
    }

    /**
     * @param {String} randomWord
     * @param {Boolean} wideTallOnly
     * @private
     */
    addRandomWordToSearch(randomWord, wideTallOnly = false) {
        const {history} = this.props;
        history.push({
            search: `?randomWord=${randomWord}&wideTallOnly=${wideTallOnly}`
        });
    }

    render() {
        const {api} = this.props;
        const {randomWord, slides, number, wideTallOnly} = this.state;

        if (!slides) {
            return (<div>Loading...</div>);
        }

        return (
            <div className="app">
                <Filter randomWord={randomWord} number={number} wideTallOnly={wideTallOnly}/>
                {slides.map(data =>
                    <div key={data.params.id}>
                        <Row xmlUrl={data.xmlUrl} imageUrl={data.slidegen2Url} params={data.params} api={api} showLink={true}/>
                    </div>
                )}
                <Filter randomWord={randomWord} number={number} wideTallOnly={wideTallOnly}/>
            </div>
        );
    }
}

Home.propTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    history: PropTypes.object.isRequired
};

const HomeWithRouter = withRouter(Home);

export { HomeWithRouter as Home };