import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Api } from '../../api/Api';
import { Row } from '../row/Row';

class Detail extends Component {

    state = {
        slide: undefined
    };

    async componentDidMount() {
        const {api, match} = this.props;
        const {id} = match.params;

        const slide = await api.getSlide(id);
        this.setState({
            slide
        });
    }

    render() {
        const {api} = this.props;
        const {slide} = this.state;

        if (!slide) {
            return (<div>Loading...</div>);
        }

        return (
            <Row xmlUrl={slide.xmlUrl} imageUrl={slide.slidegen2Url} params={slide.params} api={api} showLink={false}/>
        );
    }
}

Detail.propTypes = {
    api: PropTypes.instanceOf(Api).isRequired
};

const DetailWithRouter = withRouter(Detail);
export { DetailWithRouter as Detail };