import axios from 'axios';

export class Api {

    /**
     * @param {String} xmlUrl
     * @return {Promise<String>} XML data
     */
    async getXML(xmlUrl) {
        const response = await axios.get(xmlUrl);
        return response.data;
    }

    /**
     * @param {Object} object
     * @param {String} [object.randomWord]
     * @param {Number} [object.number = 10]
     * @return {Promise<Object>} Slide metadata
     */
    async getJSON({randomWord, number= 10, wideTallOnly = false}) {
        let url;
        if (randomWord) {
            // eslint-disable-next-line no-undef
            url = `${SLIDES_TEST_DATA_SERVICE_BASE_URL}/slides/byRandomWord/${randomWord}`;
        } else {
            // eslint-disable-next-line no-undef
            url = `${SLIDES_TEST_DATA_SERVICE_BASE_URL}/random-slides/${number}`;
        }

        url = `${url}?wideTallOnly=${wideTallOnly}`;

        const response = await axios.get(url);
        return response.data;
    }

    /**
     * @param {String} stringCacheId
     * @return {Promise<Object>} Slide data
     */
    async getSlide(stringCacheId) {
        // eslint-disable-next-line no-undef
        const url = `${SLIDES_TEST_DATA_SERVICE_BASE_URL}/slides/byId/${stringCacheId}`;
        const response = await axios.get(url);
        return response.data;
    }
}