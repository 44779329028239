import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { XmlSlide } from 'tt-slide-viewer';

import './Row.scss';
import { Api } from '../../api/Api';

export class Row extends Component{

    state = {
        xmlSlide: undefined
    };

    async componentDidMount() {
        const {xmlUrl, api} = this.props;

        const xmlSlide = await api.getXML(xmlUrl);

        this.setState({
            xmlSlide
        });
    }

    render() {
        const {xmlSlide} = this.state;
        const {imageUrl, params, showLink} = this.props;
        const {id, height, width} = params;

        // eslint-disable-next-line no-undef
        const baseURL = WEBAPP_BASE_URL;

        const isDocument = xmlSlide?.startsWith('<document>');

        return (
            <div className="row">
                <div>
                    <label>String Cache ID:</label>
                    {showLink ? <Link to={`/${id}`}>{id}</Link> : id}
                </div>
                <div className="content">
                    <div className="old">
                        <div className="label">Old</div>
                        <div className="slide">
                            <img src={imageUrl} alt="slide image"/>
                        </div>
                    </div>

                    {xmlSlide && !isDocument &&
                        <div className="new">
                            <div className="label">New</div>
                            <div className="slide">
                                <XmlSlide index={id}
                                    xml={xmlSlide}
                                    baseURL={baseURL}
                                    requestWidth={width}
                                    requestHeight={height}/>
                            </div>
                        </div>
                    }

                    {isDocument &&
                        <div className="document">
                            Document
                        </div>
                    }
                </div>
            </div>
        );
    }
}

Row.propTypes = {
    xmlUrl: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired,
    api: PropTypes.instanceOf(Api).isRequired,
    showLink: PropTypes.bool.isRequired
};