import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import './Filter.scss';

class Filter extends Component {

    state = {
        randomWordValue: '',
        numberValue: '',
        stringCacheId: '',
        wideTallOnly: false
    };

    componentDidMount() {
        const {randomWord, number = '', wideTallOnly} = this.props;

        this.setState({
            randomWordValue: randomWord,
            numberValue: number + '',
            wideTallOnly
        });
    }

    handleRandomWordChange = (event) => {
        this.setState({
            randomWordValue: event.target.value
        });
    };

    handleNumberChange = (event) => {
        this.setState({
            numberValue: event.target.value
        });
    };

    handleStringCacheIdChange = (event) => {
        this.setState({
            stringCacheId: event.target.value
        });
    };

    handleWideTallOnlyChange = (event) => {
        this.setState({
            wideTallOnly: event.target.checked
        }, this.handleRefreshClick);  // Refresh filter immediately after updating state
    };

    handleRefreshClick = () => {
        const {history} = this.props;
        const params = new URLSearchParams(history.location.search);

        const {randomWordValue, wideTallOnly} = this.state;

        if (randomWordValue.length) {
            params.set('randomWord', randomWordValue);
        } else {
            params.delete('randomWord');
        }
        params.delete('number');

        params.set('wideTallOnly', wideTallOnly);

        this.navigateWithParams(params);
    };

    navigateWithParams(params) {
        const {history} = this.props;
        history.push({
            search: `?${params.toString()}`
        });
        // Need to refresh since the path didn't change
       this.refresh(history);
    }

    refresh(history) {
        history.go(0);
    }

    handleRandomClick = () => {
        const params = new URLSearchParams(window.location.search);

        const {numberValue,wideTallOnly} = this.state;

        if (numberValue.length) {
            params.set('number', numberValue);
        } else {
            params.delete('number');
        }
        params.delete('randomWord');

        params.set('wideTallOnly', wideTallOnly);

        this.navigateWithParams(params);
    };

    handleSubmitClick = () => {
        const {stringCacheId} = this.state;
        this.navigateWithPath(stringCacheId);
    };

    navigateWithPath(path) {
        const {history} = this.props;
        history.push(path);
    }

    render() {
        return (
            <div className="filter">
                <div>
                    <label>Options:</label>
                </div>
                <div>
                    <label>Show Wide/Tall Slides Only:</label>
                    <input type="checkbox" checked={this.state.wideTallOnly} onChange={this.handleWideTallOnlyChange}/>
                </div>

                <hr/>

                <div>
                    <label>Random Word:</label>
                    <input type="text" value={this.state.randomWordValue} onChange={this.handleRandomWordChange}/>
                    <button type="button" onClick={this.handleRefreshClick}>Refresh</button>
                </div>
                <div>
                    <label>Number of Slides:</label>
                    <input type="text" value={this.state.numberValue} onChange={this.handleNumberChange}/>
                    <button type="button" onClick={this.handleRandomClick}>Random</button>
                </div>
                <div>
                    <label>String Cache ID:</label>
                    <input type="text" value={this.state.stringCacheId} onChange={this.handleStringCacheIdChange}/>
                    <button type="button" onClick={this.handleSubmitClick}>Submit</button>
                </div>

                <hr/>
            </div>
        );
    }
}

Filter.propTypes = {
    randomWord: PropTypes.string.isRequired,
    number: PropTypes.number,
    history: PropTypes.object.isRequired,
    wideTallOnly: PropTypes.bool.isRequired
};

const FilterWithRouter = withRouter(Filter);

export { FilterWithRouter as Filter };